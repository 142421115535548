/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */
import { ListAlt as ListAltIcon } from '@mui/icons-material'
import { AppBar, Box, SvgIcon, Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'

import useReactQuery from 'hooks/react_query'
import useUrls from 'hooks/useUrls'

// @ts-ignore
import DisabledByDefault from './disabled_by_default.svg?react'
import './SearchForm.css'
import FilterListContent from './FilterListContent'

const useStyles = makeStyles(theme => ({
    root: {
        '& [class*="MuiTab-root"]': {
            minWidth: 10,
            marginRight: theme.spacing(4),
            padding: theme.spacing(1)
        }
    },
    tabLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            marginRight: theme.spacing(1)
        }
    },
    tab: {
        '& span:first-child': {
            display: 'flex',
            alignItems: 'flex-start'
        }
    },
    selectedTab: {
        opacity: '1.0 !important',
        '& div': {
            fontWeight: 'bold'
        }
    }
}))

export default function FilterComponent({
    selectedFilters,
    setSelectedFilters
}: {
    selectedFilters: SearchFilters
    setSelectedFilters: (SearchFilters) => void
}) {
    const theme = useTheme()
    const urls = useUrls()
    const { data: allFilters } = useReactQuery(urls['search:search_context'](), ['SearchContext'])
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [tabsVisible, setTabsVisible] = useState(false)
    const titleMap = {
        targetAudiences: gettext('Target audience'),
        additionalFilters: gettext('Additional Filters'),
        publishingYears: gettext('Year'),
        contentTypes: gettext('Type'),
        keywords: gettext('Topic')
    }

    if (!allFilters) {
        return null
    }

    const handleChange = (event, newValue) => {
        if (newValue === value && tabsVisible) {
            setTabsVisible(false)
        } else {
            setValue(newValue)
            setTabsVisible(true)
        }
    }

    return (
        <React.Fragment>
            <AppBar position="static" color="default" style={{ padding: theme.spacing(1) }}>
                <Tabs
                    className={classes.root}
                    variant="scrollable"
                    value={value}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    indicatorColor="secondary"
                    textColor="inherit"
                >
                    {Object.keys(allFilters).map((category, index) => (
                        <Tab
                            className={
                                tabsVisible && value === index ? clsx(classes.selectedTab, classes.tab) : classes.tab
                            }
                            key={category}
                            onClick={event => handleChange(event, index)}
                            label={
                                <Box className={classes.tabLabel}>
                                    {value === index && tabsVisible && (
                                        <SvgIcon
                                            component={DisabledByDefault}
                                            htmlColor={theme.palette.secondary.main}
                                            fontSize="small"
                                        />
                                    )}
                                    {(value !== index || !tabsVisible) && <ListAltIcon fontSize="small" />}
                                    {titleMap[category]}
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
            </AppBar>
            {tabsVisible && (
                <React.Fragment>
                    {Object.keys(allFilters).map((category, index) => (
                        <div
                            key={category}
                            className="search-filters"
                            style={{
                                visibility: value === index ? 'visible' : 'hidden'
                            }}
                        >
                            <FilterListContent
                                allFilters={allFilters}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                                category={category}
                                setTabsVisible={setTabsVisible}
                            />
                        </div>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}
